.edit-game-review-modal {
    .modal-header {
        border-bottom: 1px solid #dee2e6;
        padding: 1rem;
    }

    .modal-body {
        padding: 1.5rem;
    }

    .bug-reports-section {
        background-color: #f8f9fa;
        border-radius: 0.5rem;
        padding: 1rem;
        margin-bottom: 1.5rem;

        .bug-reports-list {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }

        .bug-report-item {
            background-color: white;
            border: 1px solid #dee2e6;
            border-radius: 0.375rem;
            padding: 0.75rem;

            .bug-title {
                font-weight: 500;
                margin-right: 1rem;
            }

            .bug-badges {
                display: flex;
                gap: 0.5rem;
            }
        }
    }

    .form-group {
        margin-bottom: 1.5rem;

        label {
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        textarea {
            resize: vertical;
            min-height: 100px;
        }
    }

    .selected-images {
        .image-preview-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            gap: 1rem;
            margin-top: 1rem;
        }

        .image-preview {
            position: relative;
            border-radius: 0.375rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
            }

            .remove-image {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                width: 24px;
                height: 24px;
                padding: 0;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.25rem;
                line-height: 1;
            }
        }
    }
}

.bug-reports-preview {
    background-color: #f8f9fa;
    border-radius: 0.5rem;
    padding: 1rem;

    .bug-stats {
        .badge {
            font-size: 0.85rem;
            padding: 0.5em 0.75em;
        }
    }

    .bug-reports-list {
        .bug-report-item {
            background-color: white;
            box-shadow: 0 1px 3px rgba(0,0,0,0.1);
            transition: all 0.2s ease-in-out;

            &:hover {
                box-shadow: 0 2px 5px rgba(0,0,0,0.15);
            }

            h6 {
                color: #495057;
                font-weight: 600;
            }

            .bug-badges {
                .badge {
                    font-size: 0.75rem;
                    padding: 0.4em 0.6em;
                }
            }

            .text-muted {
                font-size: 0.85rem;
            }

            .bug-images {
                .image-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                    gap: 0.5rem;
                    margin-top: 0.5rem;

                    .image-item {
                        position: relative;
                        aspect-ratio: 1;
                        overflow: hidden;
                        border-radius: 0.25rem;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            ol {
                padding-left: 1.5rem;
                margin-bottom: 0;
            }
        }
    }
}

// Responsive styles
@media (max-width: 768px) {
    .edit-game-review-modal {
        .modal-body {
            padding: 1rem;
        }

        .bug-reports-section {
            padding: 0.75rem;
        }

        .image-preview-container {
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        }
    }
} 