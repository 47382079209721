.reminder-modal-content {
    .select-all-container {
        padding: 0.5rem 0;
        border-bottom: 1px solid #dee2e6;

        .form-check {
            margin: 0;
            
            label {
                font-weight: 500;
                color: #495057;
            }
        }
    }

    .users-table-container {
        margin-top: 1rem;

        table {
            th {
                background-color: #f8f9fa;
                color: #495057;
                font-weight: 600;
                border-bottom: 2px solid #dee2e6;
            }

            td {
                vertical-align: middle;

                .form-check {
                    margin: 0;
                }

                .badge {
                    font-size: 0.85rem;
                    padding: 0.35rem 0.65rem;
                }

                .small {
                    font-size: 0.85rem;
                    line-height: 1.4;

                    &.text-muted {
                        color: #6c757d;
                    }
                }
            }

            tbody tr {
                &:hover {
                    background-color: #f8f9fa;
                }

                td {
                    padding: 0.75rem;
                }
            }
        }
    }
}

.modal-footer {
    .btn-primary {
        min-width: 120px;

        .spinner-border {
            width: 1rem;
            height: 1rem;
            border-width: 0.15em;
        }
    }
}

@media (max-width: 768px) {
    .reminder-modal-content {
        .users-table-container {
            table {
                td, th {
                    padding: 0.5rem;
                }

                .badge {
                    font-size: 0.75rem;
                    padding: 0.25rem 0.5rem;
                }

                .small {
                    font-size: 0.75rem;
                }
            }
        }
    }
} 