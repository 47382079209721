@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");

* {
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif;
}
.profile-card-single {
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background: #fff;
    border: 1px solid #b0acac3b;
    &:hover {
        transition: all 0.3s ease-in-out;
        // box-shadow: #6de1c43d 3px 3px 12px;
        box-shadow:
            #6de1c43d 0px 54px 55px,
            #6de1c43d 0px -12px 30px,
            rgba(0, 0, 0, 0.07) 0px 4px 6px,
            rgba(0, 0, 0, 0.07) 0px 12px 13px,
            rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    .text-align-left {
        text-align: left !important;
    }
    .dp-img img {
        height: 120px;
        width: 120px;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;

        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
        border-top: 3px solid #347d71;
        border-bottom: 3px solid #347d71;
        border-left: 0px solid #347d71;
        border-right: 3px solid #347d71;

    }

    .icons {
        margin: 0px 30px;
        font-size: 1.3em;
        display: flex;
        justify-content: center;
        a {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .icons button {
        width: fit-content;
        height: fit-content;
        border: none;
        font-size: 0.7em;
    }

    ion-icon:hover {
        color: #58a497;
        transition: 0.5s;
    }
    .name {
        font-size: 1.15em;
        font-weight: bolder;
        letter-spacing: 1px;
    }

    .job_title {
        font-size: 0.9em;
        font-weight: bolder;
        color: gray;
        margin-top: -2px;
    }
    .right-description {
        border-left: 1px solid #000;
    }
}

.profile-card-containe {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    // background: #a4bdb7;

    .box {
        width: 100%;
        height: fit-content;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        background: #fff;
        border: 1px solid #b0acac3b;
        &:hover {
            transition: all 0.3s ease-in-out;
            // box-shadow: #6de1c43d 3px 3px 12px;
            box-shadow:
                #6de1c43d 0px 54px 55px,
                #6de1c43d 0px -12px 30px,
                rgba(0, 0, 0, 0.07) 0px 4px 6px,
                rgba(0, 0, 0, 0.07) 0px 12px 13px,
                rgba(0, 0, 0, 0.09) 0px -3px 5px;
        }
        .short-description {
            height: 70px;
            line-height: 16px;
            font-size: 13px;
        }
    }

    .box1 {
        margin-top: 10px;
    }

    .content {
        margin: 15px 2px;
    }

    .image img {
        height: auto;
        width: 120px;
        border-radius: 50%;

        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }

    .level {
        font-size: 0.7em;
        background-color: rgb(164, 189, 183, 0.5);
        width: 50px;
        padding: 3px;
        border-radius: 5px;
        font-weight: bolder;
        letter-spacing: 1px;

        display: block;
        margin: 0px auto 10px;
    }

    .name {
        font-size: 1.25em;
        font-weight: bolder;
        letter-spacing: 1px;
    }

    .job_title {
        font-size: 0.9em;
        font-weight: bolder;
        color: gray;
        margin-top: -2px;
    }

    .job_discription {
        font-size: 0.7em;
        color: gray;
        margin: 10px 30px 20px;
    }

    .icons {
        margin: 0px 30px;
        font-size: 1.3em;
        display: flex;
        justify-content: space-around;
    }

    .icons button {
        width: fit-content;
        height: fit-content;
        border: none;
        font-size: 0.7em;
    }

    ion-icon:hover {
        color: #58a497;
        transition: 0.5s;
    }

    .cta {
        width: 80px;
        height: 30px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 12px;
        padding: 3px 4px !important;
    }

    .button {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        margin: 20px 30px 0px;
    }

    .button .message {
        background: #ededed;
        border: 2px solid #000;
    }

    .button .connect {
        background-color: #000;
        color: #ededed;
        border: none;
    }
    .cta.connect:hover {
        letter-spacing: 1px;
        transition: 0.5s;
    }
    .cta.message:hover {
        letter-spacing: 1px;
        transition: 0.5s;
        background: rgba(88, 164, 151, 0.5);
    }
}

/* Content-1:Start */

/* Content-1:End */
