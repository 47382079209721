.payslip-btn{
    // padding: 8px 20px;
    outline: 1px solid #51796F;
    margin-right: 8px;
    margin-bottom: 16px;
    a{
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        &.link-text{
            color: #000;

        }
        &:hover{
            text-decoration: underline;
        }
    }
}