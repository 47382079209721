.policy-details{
    box-shadow: 0px 0px 5px 0px #ccc;

    h1{
        font-size: 26px;
        font-weight: 800;
        color: #51796F;
        text-align: center;
    }
    h2{
        font-size: 20px;
        font-weight: 800;
        color: #51796F;
    }
}