.analytics-container {
    .card {
        border: 1px solid #dee2e6;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        .card-header {
            background-color: #f8f9fa;
            border-bottom: 1px solid #dee2e6;
            padding: 1rem 1.5rem;

            h5 {
                color: #495057;
                font-weight: 600;
            }
        }

        .card-body {
            padding: 1.5rem;
        }
    }

    table {
        margin-bottom: 0;

        th {
            background-color: #f8f9fa;
            color: #495057;
            font-weight: 600;
            border-bottom: 2px solid #dee2e6;
            padding: 1rem;
        }

        td {
            vertical-align: middle;
            padding: 1rem;
            color: #212529;

            small.text-muted {
                color: #6c757d;
                font-size: 0.875rem;
            }
        }

        tbody tr:hover {
            background-color: #f8f9fa;
        }
    }
}

@media (max-width: 768px) {
    .analytics-container {
        .card {
            .card-header {
                padding: 1rem;
            }

            .card-body {
                padding: 1rem;
            }
        }

        table {
            th, td {
                padding: 0.75rem;
            }
        }
    }
} 