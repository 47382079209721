.career-card{
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgba($color: #51796F, $alpha: 0.2);

    &.card-outline{
outline: 2px solid #e1555a;
    }
}
.font-14{
    font-size: 14px;
}