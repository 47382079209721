.report-calendar {
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;

  .card-body {
    padding: 1.5rem;
  }

  h5 {
    color: #333;
    font-weight: 600;
  }

  .weeks-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 1.5rem 0;
    flex-wrap: wrap;

    .week-button {
      flex: 1;
      min-width: 200px;
      text-align: left;
      padding: 1rem;
      border-radius: 6px;
      transition: all 0.2s ease;
      border: 1px solid #dee2e6;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: #ffffff;
      border-color: #51796F;
      color: #51796F;

      &:hover:not(:disabled) {
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      &.selected {
        background-color: #51796F;
        border-color: #51796F;
        color: white;
        font-weight: bold !important;

        .status {
          background-color: rgba(255, 255, 255, 0.2) !important;
          color: white !important;
        }

        .action-text {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      &.missing:not(.selected) {
        background-color: white;
        border-color: #dee2e6;
        color: #666;

        &:hover:not(:disabled) {
          background-color: #f8f9fa;
        }

        .action-text {
          color: #51796F;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .date-range {
        font-weight: 500;
        margin-bottom: 0.25rem;
      }

      .status {
        font-size: 0.875rem;
        font-weight: 500;
      }

      .action-text {
        font-size: 0.8rem;
        font-style: italic;
        margin-top: auto;
      }
    }
  }

  .calendar-legend {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1.5rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    flex-wrap: wrap;

    .legend-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .legend-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &.pending {
          background-color: #ffc107;
        }

        &.reviewed {
          background-color: #28a745;
        }

        &.missing {
          background-color: #dee2e6;
          border: 2px solid #ced4da;
        }
      }

      span {
        font-size: 0.9rem;
        color: #666;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;

    .card-body {
      padding: 1rem;
    }

    .weeks-container {
      flex-direction: column;
      gap: 0.75rem;
      margin: 1rem 0;

      .week-button {
        width: 100%;
        padding: 0.75rem;

        .date-range {
          font-size: 0.9rem;
        }

        .status {
          font-size: 0.8rem;
        }

        .action-text {
          font-size: 0.75rem;
        }
      }
    }

    .calendar-legend {
      gap: 1rem;
      padding: 0.75rem;
      margin-top: 1rem;

      .legend-item {
        span {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.week-selector-card {
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .card-body {
    padding: 1.5rem;
  }

  .week-selector {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;

    .btn {
      flex: 1;
      padding: 1rem 1.5rem;
      text-align: center;
      transition: all 0.2s ease;
      border-color: #51796F;
      white-space: normal;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #51796F;
        border-color: #51796F;
        color: white;

        small {
          color: rgba(255, 255, 255, 0.9) !important;
        }
      }

      &.btn-primary {
        background-color: #51796F;
        border-color: #51796F;

        small {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      &.btn-outline-primary {
        color: #51796F;
        background-color: white;

        small {
          color: #666;
        }
      }

      small {
        font-size: 0.85rem;
        margin-top: 0.5rem;
        display: block;
        font-weight: normal;
      }
    }
  }

  @media (max-width: 576px) {
    .week-selector {
      flex-direction: column;
      gap: 1rem;

      .btn {
        width: 100%;
        min-height: 70px;
      }
    }
  }
} 