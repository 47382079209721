.monthly-score-card {
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;

  .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #eee;
    padding: 1.25rem;

    h3 {
      margin: 0;
      font-size: 1.5rem;
      color: #333;
      font-weight: 600;
    }
  }

  .card-body {
    padding: 1.5rem;
  }

  .score-summary {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eee;

    .average-score {
      text-align: center;

      h4 {
        color: #51796F;
        font-size: 1.1rem;
        margin-bottom: 1rem;
        font-weight: 500;
      }

      .score-display {
        margin-bottom: 1rem;

        .score {
          font-size: 2.5rem;
          font-weight: 600;
          color: #51796F;
        }

        .total {
          font-size: 1.5rem;
          color: #666;
          margin-left: 0.5rem;
        }
      }

      .score-progress {
        height: 0.5rem;
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }

  .weekly-breakdown {
    h4 {
      color: #51796F;
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
      font-weight: 500;
    }

    .week-score {
      margin-bottom: 1.5rem;
      padding: 1rem;
      background-color: #f8f9fa;
      border-radius: 6px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #f0f2f5;
      }

      .week-label {
        font-weight: 500;
        color: #333;
        margin-bottom: 0.5rem;
      }

      .score-value {
        font-size: 1.1rem;
        color: #51796F;
        margin-bottom: 0.75rem;
        font-weight: 500;
      }

      .score-progress {
        height: 0.4rem;
        border-radius: 4px;
      }
    }

    .no-scores {
      text-align: center;
      padding: 2rem;
      color: #666;
      background-color: #f8f9fa;
      border-radius: 6px;
      font-style: italic;
    }
  }

  .progress {
    background-color: rgba(81, 121, 111, 0.1);

    .progress-bar {
      &.bg-success {
        background-color: #51796F !important;
      }
      &.bg-info {
        background-color: #4B8F84 !important;
      }
      &.bg-warning {
        background-color: #45A599 !important;
      }
      &.bg-danger {
        background-color: #3FBCAE !important;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 15px 0;

    .card-header {
      padding: 1rem;

      h3 {
        font-size: 1.25rem;
      }
    }

    .card-body {
      padding: 1rem;
    }

    .score-summary {
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;

      .average-score {
        .score-display {
          .score {
            font-size: 2rem;
          }
          .total {
            font-size: 1.25rem;
          }
        }
      }
    }

    .weekly-breakdown {
      .week-score {
        padding: 0.75rem;
        margin-bottom: 1rem;
      }
    }
  }
} 