.personal-profile{
    .profile-image{
        width: 150px;
        height: 150px;
        border-radius: 100%;
        object-fit: cover;
        object-position: top;
        background-color: #51796F;
    }

 
}
.profile-card-single{
    position: relative;

    .edit-btn-container{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
       
    }
}
.dp-container{
    position: relative;
    width: 150px;

    &:hover{
        .edit-btn{
            visibility: visible;
        }
    }
    .edit-btn{
        visibility: hidden;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}

.personal-profile-page{
    .card.active{
        outline: 3px solid #65cab1;
    }
}