.view-game-review-modal {
    .modal-header {
        border-bottom: 1px solid #dee2e6;
        padding: 1rem;
    }

    .modal-body {
        padding: 1.5rem;
    }

    .bug-reports-section {
        background-color: #f8f9fa;
        border-radius: 0.5rem;
        padding: 1rem;
        margin-bottom: 1.5rem;

        .bug-stats {
            display: flex;
            gap: 0.5rem;
        }

        .accordion {
            .accordion-item {
                border: 1px solid #dee2e6;
                margin-bottom: 0.5rem;
                border-radius: 0.375rem;
                overflow: hidden;

                &:last-child {
                    margin-bottom: 0;
                }

                .accordion-header {
                    .accordion-button {
                        padding: 0.75rem 1rem;
                        background-color: white;
                        border: none;
                        box-shadow: none;

                        &:not(.collapsed) {
                            background-color: #f8f9fa;
                        }

                        &::after {
                            margin-left: 1rem;
                        }
                    }
                }

                .accordion-body {
                    padding: 1rem;
                    background-color: white;
                }
            }
        }

        .bug-details {
            .bug-description,
            .bug-steps,
            .bug-expected,
            .bug-actual {
                h6 {
                    color: #495057;
                    margin-bottom: 0.5rem;
                }

                p {
                    margin-bottom: 0;
                    white-space: pre-wrap;
                }

                ol {
                    margin-bottom: 0;
                    padding-left: 1.5rem;
                }
            }

            .bug-images {
                h6 {
                    color: #495057;
                    margin-bottom: 0.75rem;
                }

                .image-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                    gap: 1rem;

                    .image-item {
                        cursor: pointer;
                        transition: transform 0.2s;
                        
                        &:hover {
                            transform: scale(1.05);
                        }
                        
                        img {
                            width: 100%;
                            height: 150px;
                            object-fit: cover;
                        }
                    }
                }
            }

            .bug-files {
                .files-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                    gap: 1rem;

                    .file-preview-item {
                        border: 1px solid #dee2e6;
                        border-radius: 0.25rem;
                        padding: 1rem;
                        background: #f8f9fa;
                        
                        .file-name {
                            font-size: 0.875rem;
                            color: #6c757d;
                            word-break: break-word;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        
                        .file-content {
                            margin-top: 0.5rem;
                            
                            .btn {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .table {
            font-size: 0.9rem;
            
            th {
                background-color: #f8f9fa;
                border-bottom: 2px solid #dee2e6;
            }
            
            td {
                vertical-align: middle;
                
                .badge {
                    font-size: 0.8rem;
                    padding: 0.35em 0.65em;
                }
            }
        }

        .table-responsive {
            max-height: 400px;
            overflow-y: auto;
            
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 4px;
            }
        }
    }

    .review-details {
        .meta-info {
            background-color: #f8f9fa;
            padding: 1rem;
            border-radius: 0.5rem;
            margin-bottom: 1.5rem;

            ul {
                margin: 0.5rem 0;
                padding-left: 0;

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    padding: 0.5rem;
                    background-color: white;
                    border-radius: 0.25rem;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .badge {
                        font-size: 0.75rem;
                        padding: 0.25rem 0.5rem;
                    }
                }
            }
        }

        .feedback-sections {
            .feedback-section {
                h6 {
                    color: #495057;
                    margin-bottom: 0.75rem;
                }

                .feedback-content {
                    background-color: #f8f9fa;
                    border-radius: 0.375rem;
                    padding: 1rem;
                    white-space: pre-wrap;
                }
            }
        }

        .images-section {
            .image-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                gap: 1rem;
                margin-top: 1rem;
            }

            .image-item {
                border-radius: 0.375rem;
                overflow: hidden;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                }
            }
        }
    }
}

.image-preview-modal {
    .modal-body {
        padding: 0;
        background-color: #000;
    }

    .full-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        padding: 1rem;

        img {
            max-width: 100%;
            max-height: 80vh;
            object-fit: contain;
        }
    }
}

.files-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 0.5rem;

    .file-preview-item {
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        padding: 1rem;
        background: #fff;
        
        .file-name {
            font-size: 0.875rem;
            color: #6c757d;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
        .file-content {
            display: flex;
            justify-content: center;
            align-items: center;
            
            .image-item {
                cursor: pointer;
                width: 100%;
                
                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                    transition: transform 0.2s;
                    
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            
            .btn {
                width: 100%;
                margin-top: 0.5rem;
            }
        }
    }
}

// Add styles for text preview modal
.text-content {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 500px;
    overflow-y: auto;
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

// Responsive styles
@media (max-width: 768px) {
    .view-game-review-modal {
        .modal-body {
            padding: 1rem;
        }

        .bug-reports-section {
            padding: 0.75rem;

            .bug-details {
                .bug-images {
                    .image-grid {
                        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                    }
                }
            }
        }

        .review-details {
            .meta-info {
                padding: 0.75rem;
            }

            .feedback-sections {
                .feedback-section {
                    .feedback-content {
                        padding: 0.75rem;
                    }
                }
            }

            .images-section {
                .image-grid {
                    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                }

                .image-item {
                    img {
                        height: 150px;
                    }
                }
            }
        }
    }

    .image-preview-modal {
        .full-image-container {
            min-height: 300px;
        }
    }
}

.bug-files {
    .files-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 1rem;
        margin-top: 1rem;

        .file-preview-item {
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            padding: 1rem;
            background: #fff;
            
            .file-name {
                font-size: 0.875rem;
                color: #6c757d;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            
            .file-content {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100px;
                
                img {
                    max-height: 150px;
                    width: auto;
                    object-fit: contain;
                }
                
                .btn {
                    width: 100%;
                }
            }
        }
    }
} 