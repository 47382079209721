.performance-review {
  padding: 2rem 0;

  h2 {
    color: #333;
    margin-bottom: 0.5rem;
  }

  .text-muted {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 2rem;

    .nav-link {
      color: #666 !important;
      border: none;
      padding: 1rem 1.5rem;
      font-weight: 500;
      position: relative;

      &:hover {
        border: none;
        color: #333 !important;
      }

      &.active {
        color: #51796F !important;
        background: none;
        border: none;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          height: 2px;
          background-color: #51796F;
        }
      }
    }
  }

  .tab-content {
    padding: 1rem 0;
  }

  .alert {
    margin-bottom: 2rem;
  }
}
button.selected{
  font-weight: bold !important;
}
.performance-review-section {
  min-height: calc(100vh - 60px);
  padding: 1.5rem 0;
  background-color: #f8f9fa;

  .performance-review {
    .vertical-tabs {
      background-color: white;
      border-radius: 8px;
      padding: 1.5rem 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      height: fit-content;
      position: sticky;
      top: 80px;

      .nav-pills {
        gap: 0.75rem;

        .nav-link {
          color: #51796F !important;
          padding: 1rem 1.5rem;
          border-radius: 6px;
          transition: all 0.2s ease;
          font-weight: 500;
          
          &:hover {
            background-color: rgba(81, 121, 111, 0.1);
          }

          &.active {
            background-color: #51796F !important;
            color: white !important;

            &:hover {
              background-color: darken(#51796F, 5%) !important;
            }
          }
        }
      }
    }

    .tab-content-wrapper {
      .tab-content {
        background-color: transparent;
        padding: 0;
      }

      .tab-pane {
        opacity: 0;
        transform: translateY(10px);
        transition: all 0.3s ease;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem 0;

    .performance-review {
      .vertical-tabs {
        margin-bottom: 1.5rem;
        padding: 1rem;
        position: relative;
        top: 0;

        .nav-pills {
          flex-direction: row;
          overflow-x: auto;
          padding-bottom: 0.5rem;
          gap: 0.5rem;
          
          .nav-link {
            white-space: nowrap;
            padding: 0.75rem 1.25rem;
            min-width: max-content;
          }
        }
      }
    }
  }
} 