.bug-resolution-container {
    .filters-section {
        background-color: #f8f9fa;
        padding: 1.5rem;
        border-radius: 0.5rem;
        margin-bottom: 2rem;

        .form-group {
            margin-bottom: 0;
        }
    }

    .results-summary {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .table {
        th {
            background-color: #f8f9fa;
            border-bottom: 2px solid #dee2e6;
        }

        td {
            vertical-align: middle;
        }

        .badge {
            font-size: 0.85rem;
            padding: 0.5em 0.75em;
        }

        .text-muted {
            font-size: 0.85rem;
        }
    }

    .btn-outline-primary {
        &:hover {
            background-color: #0d6efd;
            color: white;
        }
    }
}

.my-bugs-section {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;

    .card {
        border: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);

        .card-header {
            background-color: #e9ecef;
            border-bottom: 1px solid #dee2e6;
            padding: 1rem;

            h5 {
                color: #495057;
                font-weight: 600;
            }

            .status-filter-group {
                display: flex;
                gap: 0.5rem;

                .btn {
                    min-width: 100px;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        transform: translateY(-1px);
                    }

                    &.btn-primary {
                        box-shadow: 0 2px 4px rgba(13, 110, 253, 0.2);
                    }
                }
            }
        }

        .card-body {
            padding: 1.5rem;

            .no-data-message {
                color: #6c757d;
                font-size: 1rem;
                padding: 2rem;
                background-color: #f8f9fa;
                border-radius: 0.5rem;
                border: 1px dashed #dee2e6;
            }
        }
    }

    .table {
        margin-bottom: 0;

        th {
            background-color: #f8f9fa;
            border-bottom: 2px solid #dee2e6;
            color: #495057;
            font-weight: 600;
        }

        td {
            vertical-align: middle;
            color: #212529;
        }

        .badge {
            font-size: 0.85rem;
            padding: 0.5em 0.75em;
        }

        .text-muted {
            font-size: 0.85rem;
        }
    }

    .btn-outline-primary {
        &:hover {
            background-color: #0d6efd;
            color: white;
        }
    }
} 