.weekly-report-form {
  margin-bottom: 2rem;

  .card-body {
    padding: 2rem;
  }

  .form-section {
    margin-bottom: 2rem;

    h4 {
      color: #333;
      margin-bottom: 1rem;
    }

    .text-muted {
      font-size: 0.9rem;
    }
  }

  .reviewer-info {
    background-color: rgba(23, 162, 184, 0.1);
    border-color: rgba(23, 162, 184, 0.2);
    color: #0c5460;

    .score-badge {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      background-color: #51796F !important;
    }

    strong {
      color: #0c5460;
    }

    p {
      color: #124a54;
      margin-top: 0.5rem;
    }

    .text-muted {
      color: #517a82 !important;
    }
  }

  .form-label {
    font-weight: 500;
    color: #666;
  }

  .form-control {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      border-color: #80bdff;
    }
  }

  .quill-editor {
    .ql-container {
      min-height: 120px;
      font-size: 1rem;
      border-radius: 0 0 4px 4px;
    }

    .ql-toolbar {
      border-radius: 4px 4px 0 0;
    }

    .ql-editor {
      min-height: 150px;
      
      &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      p {
        margin-bottom: 0.5rem;
      }

      ul, ol {
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;
      }

      h1, h2, h3 {
        margin: 1rem 0 0.5rem;
        color: #333;
      }

      strong {
        color: #333;
      }

      a {
        color: #51796F;
        text-decoration: underline;
        
        &:hover {
          color: darken(#51796F, 10%);
        }
      }
    }

    &.ql-focus {
      .ql-toolbar,
      .ql-container {
        border-color: #80bdff;
      }
    }

    &.readonly {
      .ql-toolbar {
        display: none;
      }
      .ql-container {
        border: none;
        border-radius: 4px;
      }

      .ql-editor {
        padding: 1rem;
        color: #495057;
        cursor: default;

        &:focus {
          box-shadow: none;
          border-color: #e9ecef;
        }
      }
    }
  }

  .submit-button {
    background-color: #51796F !important;
    border-color: #51796F !important;
    padding: 0.75rem 2rem;
    font-weight: 500;

    &:hover {
      background-color: darken(#51796F, 5%) !important;
      border-color: darken(#51796F, 5%) !important;
    }

    &:disabled {
      background-color: lighten(#51796F, 20%) !important;
      border-color: lighten(#51796F, 20%) !important;
    }
  }

  .alert {
    border-radius: 6px;
    font-size: 0.95rem;

    &.alert-warning {
      background-color: rgba(255, 193, 7, 0.1);
      border-color: rgba(255, 193, 7, 0.2);
      color: #856404;
    }

    &.alert-info {
      background-color: rgba(23, 162, 184, 0.1);
      border-color: rgba(23, 162, 184, 0.2);
      color: #0c5460;
    }
  }

  // Styles for read-only report display
  .report-display {
    h4 {
      color: #333;
      margin: 2rem 0 1rem;
      font-size: 1.25rem;
      font-weight: 600;

      &:first-child {
        margin-top: 0;
      }
    }

    div[dangerouslySetInnerHTML] {
      background-color: #f8f9fa;
      padding: 1.5rem;
      border-radius: 6px;
      margin-bottom: 2rem;
      color: #495057;
      font-size: 1rem;
      line-height: 1.6;

      p {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul, ol {
        margin-bottom: 1rem;
        padding-left: 1.5rem;

        li {
          margin-bottom: 0.5rem;
        }
      }

      strong {
        color: #333;
      }
    }
  }

  @media (max-width: 768px) {
    .card-body {
      padding: 1.5rem;
    }

    .form-section {
      margin-bottom: 1.5rem;
    }

    .report-display {
      h4 {
        font-size: 1.1rem;
        margin: 1.5rem 0 0.75rem;
      }

      div[dangerouslySetInnerHTML] {
        padding: 1rem;
        font-size: 0.95rem;
      }
    }

    .quill-editor {
      .ql-container {
        min-height: 120px;
      }

      .ql-editor {
        min-height: 120px;
      }
    }
  }
} 