


$theme-colors: (
    "primary": #51796F,
    "info": #00b1eb,
    "danger": #DC3545,
    "orange": #DC3545,
    "warning": #ffbb00,
    "secondary": #848484b3,
    "light": #e3ecef,
    "success": #51796F,
    "dark": #0e2b63,
    "white": #ffffff,

    "amber": #FFBF00
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./fonts.scss";

.scroll-flow{
    overflow: scroll;
}
.btn-light{
    background-color: #99a4ba;
}

html{
    scroll-behavior: smooth !important;
}

.inline-btn{
    display: inline-block !important;
}

.font-14{
    font-size: 14px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-18{
    font-size: 18px !important;
}
.font-30{
    font-size: 30px !important;
}
table{
    .btn-primary{
        background-color: #51796F;
    }
    .btn-sm{
        padding: 2px 4px;
        font-size: 12px !important;
    }
}

.btn-smaller{
    padding: 2px 4px;
    font-size: 12px !important;
    border-radius: 3px;
}

.form-control.low-radius{
    border-radius: 3px;
}

.bg-transparent-green{
    background-color: #1d433997;
}
.text-no-wrap{
    white-space: nowrap;
}
.text-wrap{
    white-space: normal;
}

.overflow-wrap{
    overflow-wrap: break-word; 
  }
  
  .word-break{
    word-break: break-all;
    
  }