.task-card{
    height: 100%;
    border: none;
    margin-bottom: 8px;
}

.card-warning{
    border: 2px solid #ffbb00;
}
.card-primary{
    border: 2px solid #51796F;
}

.card-danger{
    border: 2px solid #DC3545;
}
.text-right{
    text-align: right;
}

.task-container{
    height: 80vh;
    overflow: scroll;
    // background-image: url("./green_bg.jpeg");
    background-size: contain;
    background-repeat: no-repeat;

}
.comment-box{
    border-radius: 3px;
}
.overlay-primary{
    outline: 1px solid #51796F;
    z-index: 2000;
    border-radius: 3px;
    background-color: #fff;
    margin-left: 100px;
    margin-top: 10px;
    width: 320px;
    box-shadow: rgba(0, 0, 0, 0.48) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.3) 0px 1.2px 3.6px 0px;

.icon-container{
    font-size: 22px;
    margin-right: 8px;
}
.icon-container-lg{
    font-size: 22px;
    margin-right: 8px;
}
.assign-btn{
border-radius: 0px 0px 3px 3px;
}

}

.attachment-card{
    box-shadow: rgba(0, 0, 0, 0.18) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.1) 0px 1.2px 3.6px 0px;
    outline: 1px solid #51796F;
    border-radius: 3px;
    background-color: #fff;
    width: 260px;

}

