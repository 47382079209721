.view-game-testing {
    padding: 2rem 0;

    .page-heading {
        background-color: #fff;
        border-bottom: 1px solid #dee2e6;
        padding: 1rem 0;
        margin-bottom: 2rem;
    }

    .filters-section {
        background-color: #f8f9fa;
        border-radius: 0.5rem;
        padding: 1.5rem;
        margin-bottom: 2rem;

        .form-group {
            margin-bottom: 0;

            label {
                font-weight: 500;
                margin-bottom: 0.5rem;
            }

            select {
                border-radius: 0.375rem;
                border: 1px solid #dee2e6;
                padding: 0.5rem;
                width: 100%;
            }
        }
    }

    .reports-table {
        background-color: #fff;
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        th {
            background-color: #f8f9fa;
            border-bottom: 2px solid #dee2e6;
            font-weight: 600;
            padding: 1rem;
            white-space: nowrap;
        }

        td {
            padding: 1rem;
            vertical-align: middle;
        }

        tbody tr {
            &:hover {
                background-color: #f8f9fa;
            }

            .badge {
                font-size: 0.875rem;
                padding: 0.5em 0.75em;
            }

            .text-muted {
                font-size: 0.875rem;
            }
        }
    }

    .action-buttons {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;

        .btn {
            padding: 0.375rem 0.75rem;
            font-size: 0.875rem;
        }
    }
}

// Responsive styles
@media (max-width: 768px) {
    .view-game-testing {
        padding: 1rem 0;

        .filters-section {
            padding: 1rem;
        }

        .reports-table {
            th, td {
                padding: 0.75rem;
            }
        }

        .action-buttons {
            flex-direction: column;
            gap: 0.25rem;

            .btn {
                width: 100%;
            }
        }
    }
} 