.bug-report-btn {
    margin-bottom: 1rem;
}

.selected-images {
    .image-preview-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 1rem;
        margin-top: 1rem;
    }

    .image-preview {
        position: relative;
        width: 100%;
        aspect-ratio: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
        }

        .remove-image {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 24px;
            height: 24px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 1.2rem;
            line-height: 1;
            z-index: 1;
        }
    }
}

// Sensitivity level colors
.form-select {
    option[value="low"] {
        color: #28a745;
    }
    option[value="medium"] {
        color: #ffc107;
    }
    option[value="high"] {
        color: #fd7e14;
    }
    option[value="critical"] {
        color: #dc3545;
    }
}

@media (max-width: 768px) {
    .selected-images {
        .image-preview-container {
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
            gap: 0.75rem;
        }
    }
} 