.editor-sidebar {
    background-color: #e9ecef;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 18px;
    .row {
        margin-right: 0;
        margin-left: 0;
    }
}
.ck.ck-editor__main{
    h1{
        display: none;
    }
}

.blog-card{
    margin-bottom: 15px;
    position: relative;
    background-color:#f3f3fe;
    transition: all .5s ease ;
    width:100%;
    a{
        display: block;
        width: 100%;
    }
    img.post-img{
        object-fit: cover;
        height:420px;
        width: 100%;
        object-position: center;
    }
    .post-overlay{
        
        background-color: rgba(0,0,0, .5);

        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
    }
    .post-content{
        position: absolute;
        bottom: 0;
        left:0;
        padding: 25px;
    }
    h5.post-title{
        margin-bottom: 15px;
        color: #d6ab60;
        letter-spacing:1px;
        font-size: 24px;
        font-weight: 700;
    }
}

.blog-content{
    h1{
        display: none;
    }
}


.sticky-top-sidebar-blog{
    position: sticky;
    top: 100px
}