.bug-reports-container {
    .filters-section {
        background-color: #f8f9fa;
        border: 1px solid #dee2e6;
        border-radius: 6px;
        padding: 1.5rem;

        .form-label {
            color: #495057;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        .form-select {
            &:focus {
                border-color: #80bdff;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
    }

    .bug-reports-table {
        background-color: white;
        border: 1px solid #dee2e6;

        thead {
            background-color: #f8f9fa;

            th {
                color: #495057;
                font-weight: 600;
                border-bottom: 2px solid #dee2e6;
                padding: 1rem;
            }
        }

        tbody {
            tr {
                &:hover {
                    background-color: #f8f9fa;
                }

                td {
                    vertical-align: middle;
                    padding: 1rem;
                    color: #212529;

                    .badge {
                        font-size: 0.85rem;
                        padding: 0.35rem 0.65rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .bug-reports-container {
        .filters-section {
            padding: 1rem;
        }

        .bug-reports-table {
            thead th,
            tbody td {
                padding: 0.75rem;
            }
        }
    }
} 