.admin-review-section {
  padding: 1.5rem 0;
  min-height: calc(100vh - 60px);
  background-color: #f8f9fa;

  .employee-select {
    height: 3rem;
    font-size: 1rem;
    border-radius: 6px;
    border-color: #ced4da;
    background-color: white;
    
    &:focus {
      border-color: #51796F;
      box-shadow: 0 0 0 0.2rem rgba(81, 121, 111, 0.25);
    }
  }

  .reports-list {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .card-header {
      background-color: white;
      border-bottom: 1px solid #eee;
      padding: 1rem 1.25rem;

      h5 {
        color: #333;
        font-weight: 600;
      }
    }

    .card-body {
      padding: 1rem;
    }

    .nav-pills {
      gap: 0.5rem;

      .nav-link {
        padding: 1rem;
        border-radius: 6px;
        transition: all 0.2s ease;
        cursor: pointer;
        background-color: #fff;
        color: #51796F !important;
        font-weight: bold !important;
        &:hover {
          background-color: rgba(81, 121, 111, 0.1);
        }

        &.active {
          background-color: #51796F;
          color: #fff !important;  
        }

        .report-date {
          font-weight: 500;
          margin-bottom: 0.25rem;
        }

        .report-status {
          font-size: 0.85rem;
          opacity: 0.8;
        }

        &.reviewed {
          .report-status {
            color: #28a745;
          }
        }

        &.pending {
          .report-status {
            color: #ffc107;
          }
        }
      }
    }
  }

  .report-review {
    .weekly-report-form {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem 0;

    .reports-list {
      margin-bottom: 1.5rem;

      .nav-pills {
        flex-direction: row;
        overflow-x: auto;
        padding-bottom: 0.5rem;
        
        .nav-link {
          min-width: 200px;
          white-space: nowrap;
        }
      }
    }
  }
} 

.admin-tabs{
.nav-item{
  .nav-link{
    color: #51796F !important;
    font-weight: bold !important;
    &:hover{
      background-color: rgba(81, 121, 111, 0.1);
    }
    &.active{
      background-color: #51796F;
      color: #fff !important;
    }
  }
}
}