.bug-meta {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 1.5rem;

    .bug-title {
        color: #212529;
        font-weight: 600;
    }

    .meta-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 1rem;

        .meta-item {
            strong {
                color: #495057;
                margin-right: 0.5rem;
            }

            .badge {
                font-size: 0.85rem;
                padding: 0.35rem 0.65rem;
            }
        }
    }
}

.bug-description {
    h6 {
        color: #495057;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    p {
        color: #212529;
        white-space: pre-wrap;
        margin-bottom: 0;
    }
}

.bug-images {
    h6 {
        color: #495057;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 1rem;

        .image-item {
            cursor: pointer;
            transition: transform 0.2s ease;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}

.files-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    
    .file-preview-item {
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        padding: 1rem;
        background: #fff;
        
        .file-name {
            font-size: 0.875rem;
            color: #6c757d;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
        .file-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            
            .image-item {
                cursor: pointer;
                width: 100%;
                
                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                    transition: transform 0.2s;
                    
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            
            .btn {
                width: 100%;
            }
        }
    }
}

.image-preview-modal {
    .modal-body {
        padding: 0;
        
        .full-image-container {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .bug-meta {
        padding: 1rem;

        .meta-grid {
            grid-template-columns: 1fr;
            gap: 0.75rem;
        }
    }

    .bug-images {
        .image-grid {
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            gap: 0.75rem;
        }
    }
}

.bug-details-container {
    .meta-section {
        background-color: #f8f9fa;
        border-radius: 6px;
        padding: 1rem;

        .meta-item {
            margin-bottom: 0.5rem;
            
            strong {
                color: #495057;
                margin-right: 0.5rem;
            }
        }
    }

    .content-section {
        .section-title {
            color: #495057;
            font-weight: 600;
            margin-bottom: 0.5rem;
            padding-bottom: 0.25rem;
            border-bottom: 2px solid #e9ecef;
        }

        .content-box {
            background-color: #fff;
            border: 1px solid #dee2e6;
            border-radius: 4px;
            padding: 1rem;
            min-height: 60px;
        }

        .steps-list {
            margin: 0;
            padding-left: 1.25rem;

            li {
                margin-bottom: 0.5rem;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .files-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 1rem;
        margin-top: 0.5rem;

        .file-preview-item {
            border: 1px solid #dee2e6;
            border-radius: 4px;
            padding: 0.5rem;

            .file-name {
                font-size: 0.8rem;
                color: #6c757d;
                word-break: break-all;
            }

            .file-content {
                img {
                    width: 100%;
                    height: auto;
                    cursor: pointer;
                    transition: transform 0.2s;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .document-preview {
                    text-align: center;
                    padding: 1rem;
                    background: #f8f9fa;
                    border-radius: 4px;

                    i {
                        font-size: 2rem;
                        color: #6c757d;
                    }
                }
            }
        }
    }
}

.image-preview-modal {
    .full-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            max-height: 80vh;
            object-fit: contain;
        }
    }
}

.bug-details-modal {
    .content-box {
        white-space: pre-wrap;
        word-break: break-word;
        
        &.multiline-text {
            line-height: 1.5;
            padding: 1rem;
            background-color: #fff;
            border-radius: 4px;
            
            br {
                display: block;
                content: "";
                margin-top: 0.5rem;
            }
        }
    }

    .steps-list {
        li {
            margin-bottom: 1rem;
            line-height: 1.5;
        }
    }
} 