.review-form {
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .form-label {
    font-weight: 500;
    color: #333;
    margin-bottom: 0.5rem;
  }

  .form-control {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      border-color: #80bdff;
    }

    &[type="number"] {
      font-size: 1.2rem;
      height: 3rem;
      text-align: center;
    }
  }

  .submit-button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.75rem;
    font-weight: 500;
  }
} 