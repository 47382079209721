.page-heading {
    height: 70px;
    display: flex;
    align-items: center;
    /* From https://css.glass */
    background: rgba(61, 111, 99, 0.34);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(6.6px);
    border: 1px solid rgba(61, 111, 99, 0.37);

    h1, h2, h3, h4{
        margin-bottom: 0 !important;
    }
}

// Form Label Styles
.form-label {
    font-weight: 600 !important;
    color: #495057;
    margin-bottom: 0.5rem;
}

// Toast Customization
.Toastify__toast {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
    font-size: 0.9rem;
    font-weight: 500;
    min-height: 48px;
    display: flex;
    align-items: center;

    &-container {
        padding: 8px;
    }

    &--success {
        background-color: #28a745;
        color: white;
    }

    &--error {
        background-color: #dc3545;
        color: white;
    }

    &--warning {
        background-color: #ffc107;
        color: #212529;
    }

    &--info {
        background-color: #17a2b8;
        color: white;
    }

    &-body {
        margin: 0;
        padding: 0;
    }

    &-progress {
        background: rgba(255, 255, 255, 0.7);
    }

    &-close-button {
        color: inherit;
        opacity: 0.7;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }
    }
}

