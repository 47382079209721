.testing-reports-list {
    .table {
        th {
            background-color: #f8f9fa;
            border-bottom: 2px solid #dee2e6;
        }

        td {
            vertical-align: middle;
        }
    }

    .status-badge {
        font-size: 0.875rem;
        padding: 0.5em 0.75em;
    }

    .action-buttons {
        display: flex;
        gap: 0.5rem;
    }

    .user-info {
        .user-name {
            font-weight: 500;
        }

        .user-email {
            font-size: 0.875rem;
            color: #6c757d;
        }
    }

    .status-modal {
        .form-select {
            padding: 0.5rem;
        }
    }
}

// Responsive styles
@media (max-width: 768px) {
    .testing-reports-list {
        .table {
            font-size: 0.875rem;
        }

        .action-buttons {
            flex-direction: column;
        }
    }
} 