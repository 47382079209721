.dp {
    img {
        height: 25px;
        width: 25px;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
    }
}

.basic-emp-card{
    border: 1px solid #78747488;
    border-radius: 3px;

    .dp {
        img {
            height: 25px;
            width: 25px;
            object-fit: cover;
            border-radius: 50%;
            object-position: top;

        }
    }
}